import React from "react"
import Index from "../components/pages/Index"
import { Seo } from "../components/Seo/Seo"

export default props => (
  <>
    <Seo
      title="Jouez au futoshiki, le jeu de puzzle et de réflexion ressemblant au sudoku"
      description=""
      lang={"fr"}
      defaultLangUrl="/"
      langUrls={[
        { url: "/", lang: "en" },
        { url: "/fr/", lang: "fr" },
        { url: "/de/", lang: "de" },
      ]}
    />
    <Index {...props} />
  </>
)
